import t from '@jetshop/intl';
import React, { useState, useEffect, useMemo } from 'react';
import { styled } from 'linaria/react';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import routeQuery from '../RouteQuery.gql';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import { useLocation } from 'react-router';
import { TrendLink } from '../ui/Button';
import qs from 'qs';
import { theme } from '../Theme';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import Filters from './Filters';
import { RangeFilters } from './Filters/RangeFilters';
import ProductGrid from './ProductGrid';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import AllCategories from './AllCategories.gql';
import { useQuery } from '@apollo/react-hooks';
import { CategoryList } from './CategoryListing';
import recopartCarModelQuery from '../../gql/queries/RecopartCarModelQuery.gql';
import { SortOrders } from './Filters/SortOrders';
import FilterDrawer from './Filters/FilterDrawer';
import FilterButton from './Filters/Mobile/FilterButton';
import SearchInputContainer from '../SearchPage/SearchInputContainerAlt';
import HeroSearchFormAlt from '../../elements/root/RegNrBlock/HeroSearchFormAlt';
import AutocompleteQuery from '../../components/Layout/Header/AutocompleteQuery.gql';
import { searchFieldStyles } from '../../components/SearchPage/SearchField';
import { RegNrProvider } from '../../elements/root/RegNrBlock/Context';
import CarModelSpecification from './CarModelSpecification';
import GET_CATEGORY_PRODUCTS from './query.gql';

const Container = styled(MaxWidth)`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;

  ${theme.below.lg} {
    grid-template-columns: auto;
  }

  ${theme.below.md} {
    padding-top: 0rem;
  }

  ${theme.above.sm} {
    padding-top: 1rem;
  }
`;

const CategoryWrapper = styled('div')``;

const ButtonWrapper = styled('div')`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const FilterOptionContainer = styled('div')`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  ${theme.below.sm} {
    margin-top: 0px;
  }
`;

const DrawerButtonContainer = styled('div')`
  display: flex;

  ${theme.below.sm} {
    margin-top: 0rem;
  }
`;

const SearchContainer = styled('div')`
  display: flex;
  max-width: 256px;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

const MainContent = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 32px;
  margin-left: 32px;

  ${theme.below.sm} {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 0px;
  }
`;

const SortOrdersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 5px;

  ${theme.below.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CarModelAndSortContainer = styled('div')`
  display: flex;

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const Wrapper = styled('div')`
  ${theme.above.lg} {
    margin-left: 102px;
  }
`;

const CarModelAndLicensePlateContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const Content = styled('div')`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CarModelAndLicensePlate = ({ nameOfCar }) => {
  if (!nameOfCar) return null;

  return (
    <CarModelAndLicensePlateContainer>
      Resultat baserat på bilmodell: {nameOfCar}
    </CarModelAndLicensePlateContainer>
  );
};

export const useFiltersFromLocationState = () => {
  const { state, search } = useLocation();

  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const licensePlate =
    typeof window !== 'undefined'
      ? localStorage.getItem('licensePlate')
      : undefined;

  const skip =
    !!licensePlate && params?.multiList
      ? !Object.keys(params.multiList)?.find(p => p.match(/fits-car/))
      : true;

  const licensePlateNumber =
    state?.recopartCarModel?.licensePlateNumber || licensePlate;

  const { data } = useQuery(recopartCarModelQuery, {
    variables: { licensePlateNumber },
    skip
  });
  const filtersFromLocationState =
    state?.recopartCarModel || data?.recopartCarModel;

  return filtersFromLocationState;
};

export const useCurrentCategoryFilters = category => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const filters = category.products?.filters;

  const paramsList = params?.list
    ? Object.entries(params.list).reduce((acc, [key, value]) => {
        const strippedKey = key.replace(/[0-9]{1,5}:/, '');
        const filter = filters?.find(listFilter => {
          const strippedListFilterId = listFilter.id.replace(/[0-9]{1,5}:/, '');

          return strippedListFilterId === strippedKey;
        });
        if (filter) {
          return { ...acc, [filter.id]: value };
        }

        return { ...acc, [key]: value };
      }, {})
    : {};

  const currentParams = { list: paramsList };

  const currentSearchString = qs.stringify(currentParams, { encode: true });
  const oldSearchString = qs.stringify(params, { encode: true });
  const redirect = currentSearchString !== oldSearchString;

  return {
    redirect,
    noProducts: paramsList.noProducts,
    searchString: currentSearchString
  };
};

const WindowedCategoryWrapper = props =>
  !props.category ? (
    <CategoryWrapper>
      <CategoryHeaderLoadingState />
    </CategoryWrapper>
  ) : (
    <WindowedCategory {...props} />
  );

const WindowedCategory = ({ category, result }) => {
  const [carData, setCarData] = useState(useFiltersFromLocationState());

  const handleCarBrandChange = () => {
    setCarData(null); // Reset carData when car brand changes
  };

  const { previous, next, products } = useInfinitePagination({
    result,
    query: routeQuery
  });

  const { data: allCategories, loading: allCategoriesLoading } = useQuery(
    AllCategories,
    {
      variables: { categoryId: 2 }
    }
  );

  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const routePath = category?.isDynamic ? null : result?.data?.route?.path;
  const categoryPath = useMemo(() => routePath && { path: routePath }, [
    routePath
  ]);

  const filtersFromLocationState = useFiltersFromLocationState();
  useCurrentCategoryFilters(category);
  const renderHeader = !category.data?.items?.find(
    item => item.type === 'CategoryHeader'
  );

  const content = category?.data?.items[1];
  const productGridText = category?.data?.items[2];

  return (
    <CategoryWrapper>
      {category.data && (
        <ContentRenderer
          items={category.data.items}
          components={{
            CATEGORYHEADER: props => (
              <div style={{ paddingBottom: '0rem' }}>
                <CategoryHeader
                  category={category}
                  parents={result?.data?.route?.parents}
                  {...props}
                  breadcrumbs={
                    props.breadcrumbs?.value
                      ? result?.data?.route?.breadcrumbs
                      : []
                  }
                />
              </div>
            ),
            CATEGORYBRANDS: 'Category brands'
          }}
        />
      )}
      <>
        {renderHeader && (
          <>
            <FilterContainer>
              {/* {!filtersFromLocationState?.carCode && ( */}
              <Filters
                filters={category.products?.filters || []}
                sortOrders={category.products?.sortOrders || []}
                result={result}
                onCarBrandChange={handleCarBrandChange} // Pass the handler
              />
              <CarModelSpecification carData={carData} />

              {/* )} */}
              <CarModelAndSortContainer>
                {/* Hide this component for now */}
                {/* <CarModelAndLicensePlate {...filtersFromLocationState} /> */}
                {/* <SortOrdersWrapper>
                  <SortOrders
                    sortOrders={category.products?.sortOrders || []}
                  />
                </SortOrdersWrapper> */}
                {/* <Above breakpoint="lg">
               <Wrapper>
                 <SearchInputContainer />
               </Wrapper>
             </Above> */}
              </CarModelAndSortContainer>
            </FilterContainer>
            <CategoryHeader
              category={category}
              parents={result?.data?.route?.parents}
              breadcrumbs={result?.data?.route?.breadcrumbs}
            />
          </>
        )}
        {!renderHeader && (
          <FilterContainer>
            {/* {!filtersFromLocationState?.carCode && ( */}
            <Filters
              filters={category.products?.filters || []}
              sortOrders={category.products?.sortOrders || []}
              result={result}
              onCarBrandChange={handleCarBrandChange} // Pass the handler
            />
            {/* )} */}
            <CarModelAndSortContainer>
              {/* Hide this component for now */}
              {/* <CarModelAndLicensePlate {...filtersFromLocationState} /> */}
              {/* <SortOrdersWrapper>
                <SortOrders
                  sortOrders={category.products?.sortOrders || []}
                />
              </SortOrdersWrapper> */}
              {/* <Above breakpoint="lg">
             <Wrapper>
               <SearchInputContainer />
             </Wrapper>
           </Above> */}
            </CarModelAndSortContainer>
          </FilterContainer>
        )}
        <Container>
          <Above breakpoint="lg">
            {/* Save this code below... */}
            {/* <SideBar>
              <RangeFilters filters={category.products?.filters || []} />
              <SearchInputContainer />
              <CategoryList
                filtersFromLocationState={filtersFromLocationState}
                filter={category.products?.filters || []}
                currentCategory={category}
              />
            </SideBar> */}
          </Above>
          <MainContent>
            <Below breakpoint="lg">
              {matches =>
                matches && (
                  <FilterOptionContainer>
                    <DrawerButtonContainer>
                      <DrawerTrigger id="filterDrawer">
                        {({ showTarget }) => (
                          <>
                            <FilterButton
                              showTarget={showTarget}
                              filters={category.products?.filters || []}
                            />
                            <DrawerTarget id="filterDrawer">
                              {({ hideTarget, isOpen }) => (
                                <Drawer isOpen={isOpen} right>
                                  <FilterDrawer
                                    close={hideTarget}
                                    filters={category.products?.filters || []}
                                    onCarBrandChange={handleCarBrandChange} // Pass the handler
                                  />
                                </Drawer>
                              )}
                            </DrawerTarget>
                          </>
                        )}
                      </DrawerTrigger>
                    </DrawerButtonContainer>
                    {/* Save this code below... */}
                    {/* <SearchContainer>
                      <SearchInputContainer />
                    </SearchContainer> */}
                  </FilterOptionContainer>
                )
              }
            </Below>
            {/* Save this code below... */}
            {/* <HeroSearchFormAlt
              className={searchFieldStyles}
              regInputPlaceholder={carData?.licensePlateNumber || 'Ange regnr'}
              searchInputPlaceholder=""
              autocompleteQuery={AutocompleteQuery}
            /> */}
            <SortOrdersWrapper>
              <SortOrders sortOrders={category.products?.sortOrders || []} />
            </SortOrdersWrapper>
            {previous.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    previous.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: previous.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
            <ProductGrid
              id="category"
              products={products}
              listName={category.name}
              categoryPath={categoryPath}
              loading={result.loading}
              imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
              productGridText={productGridText}
              categoryTotalResults={category.products?.totalResults}
            />
            {next.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    next.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: next.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
            {content &&
              content?.properties?.map((item, index) => (
                <Content
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item?.value?.value
                  }}
                />
              ))}
          </MainContent>
        </Container>
      </>
    </CategoryWrapper>
  );
};

export default WindowedCategoryWrapper;
